import React from 'react';
import ReactDOM from 'react-dom';
import { registrations } from './registrations';
class IntersectionHydrator {
    constructor() {
        this.mapping = {};
        this.hydratedComponents = [];
        this.registeredOnetrustBlockedComponent = false;
        this.regsiterComponents = () => {
            // Loop through the components on the window
            for (let index = 0; index < window.ReactJSWebforms.Components.length; index++) {
                const component = window.ReactJSWebforms.Components[index];
                // get the entry point for the component
                const element = document.querySelector(`#${component.id}`);
                if (element === null || !this.observer) {
                    continue;
                }
                // Find the registration we have defined so we know wich file to import
                const registrationLookup = registrations.find((x) => `Components.${x.name}` === component.name);
                if (!registrationLookup) {
                    continue;
                }
                // Create a deep copy
                const registration = JSON.parse(JSON.stringify(registrationLookup));
                // Copy over the path as it's not serialisable
                registration.import = registrationLookup.import;
                // Assign the props to the registation
                registration.props = component.props;
                // Assign the registion to the mapping
                this.mapping[component.id] = registration;
                // Hydrate based on the config
                if (registration.config.hydrate) {
                    switch (registration.config.hydrateBehaviour) {
                        case 'always': {
                            if (registration.config.blockedByOnetrust !== false &&
                                (!window.OneTrust || window.OneTrust.IsAlertBoxClosed === undefined || !window.OneTrust.IsAlertBoxClosed())) {
                                break;
                            }
                            this.hydrateComponent(registration, component.props, element);
                            break;
                        }
                        case 'in-view': {
                            if (registration.config.blockedByOnetrust !== false &&
                                (!window.OneTrust || window.OneTrust.IsAlertBoxClosed === undefined || !window.OneTrust.IsAlertBoxClosed())) {
                                break;
                            }
                            this.observer.observe(element);
                            break;
                        }
                        case 'one-trust': {
                            // do some stuff init
                        }
                    }
                }
            }
        };
        this.handleIntersection = (entries) => {
            for (const entry of entries) {
                if (!entry.isIntersecting) {
                    continue;
                }
                const mapping = this.mapping[entry.target.id];
                if (!mapping) {
                    continue;
                }
                const hasHydrated = this.hydratedComponents.indexOf(entry.target.id) > -1;
                if (!hasHydrated) {
                    this.hydrateComponent(mapping, mapping.props, entry.target);
                    this.hydratedComponents.push(entry.target.id);
                }
            }
        };
        this.hydrateComponent = async (registration, props, container) => {
            registration.import().then((e) => {
                ReactDOM.hydrate(React.createElement(e.default, props), container);
            });
        };
        if (!window.ReactJSWebforms || !window.ReactJSWebforms.Components) {
            return;
        }
        this.observer = new IntersectionObserver(this.handleIntersection, { threshold: 0, rootMargin: '0px' });
        this.regsiterComponents();
        const handle = window.setInterval(() => {
            if (!window.OneTrust ||
                window.OneTrust.IsAlertBoxClosed === undefined ||
                !window.OneTrust.IsAlertBoxClosed() ||
                this.registeredOnetrustBlockedComponent === true) {
                return;
            }
            this.registeredOnetrustBlockedComponent = true;
            this.regsiterComponents();
            window.clearTimeout(handle);
        }, 60);
    }
}
export default new IntersectionHydrator();
